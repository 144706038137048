const html = require('choo/html');
const modal = require('./modal');

module.exports = function(state, emit) {
  state.modal = null;
  return html`
    <main class="main">
      ${state.modal && modal(state, emit)}
      <div
        class="flex flex-col items-center bg-white m-4 px-6 py-8 border border-grey-30 md:border-none md:px-12 md:py-16 shadow w-full md:h-full dark:bg-grey-90"
      >
        <h1 class="text-center text-3xl font-bold">
          ${state.translate('legalTitle')}
        </h1>
        <p class="mt-2">${state.translate('legalDateStamp')}</p>
      </div>
    </main>
  `;
};
